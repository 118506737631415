.box-shadow {
  box-shadow: rgba(205, 205, 207, 0.932) 0px 30px 60px -12px inset,
    rgba(233, 227, 227, 0.3) px 18px 36px -18px inset;
}

.time-section-red {
  touch-action: manipulation;
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
  -webkit-tap-highlight-color: transparent;
}

.time-section-red:focus {
  outline-style: solid;
  outline-color: transparent;
}
.time-section-red {
  touch-action: manipulation;
  margin: 3px;
}

.time-section-red {
  text-align: center;
  touch-action: manipulation;
  position: relative;
  border: 1px solid #e71f1199;
  border-radius: 50px;
  padding: 12px 16px;
  background-color: #e71f1199;
  background-image: radial-gradient(75% 50% at 50% 0%, #f4feff, transparent),
    radial-gradient(75% 35% at 50% 80%, #8de3fc, transparent);
  box-shadow: inset 0 -2px 4px 1px #e71f1199, inset 0 -4px 4px 1px #fcc88d,
    inset 0 0 2px 1px rgba(255, 255, 255, 0.2),
    0 1px 4px 1px rgba(231, 227, 17, 0.2) x rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 0 1px 1px #e71f1199;
  transition-property: border-color, transform, background-color;
  transition-duration: 0.2s;
}
.time-section-red::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  width: 80%;
  height: 40%;
  background-image: linear-gradient(to bottom, #f4feff, transparent);
  opacity: 0.75;
}
/* CSS */
.time-section {
  touch-action: manipulation;
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
  -webkit-tap-highlight-color: transparent;
}

.time-section:focus {
  outline-style: solid;
  outline-color: transparent;
}

.time-section {
  touch-action: manipulation;
  margin: 3px;
}

.time-section {
  text-align: center;
  touch-action: manipulation;
  position: relative;
  border: 1px solid #0360df;
  border-radius: 50px;
  padding: 12px 16px;
  background-color: #0360df;
  background-image: radial-gradient(75% 50% at 50% 0%, #f4feff, transparent),
    radial-gradient(75% 35% at 50% 80%, #8de3fc, transparent);
  box-shadow: inset 0 -2px 4px 1px rgba(17, 110, 231, 0.6),
    inset 0 -4px 4px 1px #8de3fc, inset 0 0 2px 1px rgba(255, 255, 255, 0.2),
    0 1px 4px 1px rgba(17, 110, 231, 0.2), 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 0 1px 1px #116ee7;
  transition-property: border-color, transform, background-color;
  transition-duration: 0.2s;
}
.time-section::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  width: 80%;
  height: 40%;
  background-image: linear-gradient(to bottom, #f4feff, transparent);
  opacity: 0.75;
}

.custom-select {
  width: 100%;
  position: relative;
}

select {
  appearance: none;
  /*  safari  */
  -webkit-appearance: none;
  /*  other styles for aesthetics */
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

/* CSS */
.time-style {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: #000;
  background: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.time-style:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-time-style 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-time-style {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.time-style:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.button-unload {
  box-shadow: rgba(75, 154, 239, 0.2) 0 -25px 18px -14px inset,
    rgba(75, 154, 239, 0.15) 0 1px 2px, rgba(75, 154, 239, 0.15) 0 2px 4px,
    rgba(75, 154, 239, 0.15) 0 4px 8px, rgba(75, 154, 239, 0.15) 0 8px 16px,
    rgba(75, 154, 239, 0.15) 0 16px 32px; /* Blue box-shadow */
}

.button-unload:hover {
  box-shadow: rgba(75, 154, 239, 0.35) 0 -25px 18px -14px inset,
    rgba(75, 154, 239, 0.25) 0 1px 2px, rgba(75, 154, 239, 0.25) 0 2px 4px,
    rgba(75, 154, 239, 0.25) 0 4px 8px, rgba(75, 154, 239, 0.25) 0 8px 16px,
    rgba(75, 154, 239, 0.25) 0 16px 32px;
}

.load-button {
  box-shadow: rgba(208, 208, 208, 0.3) 0 -25px 18px -14px inset,
    rgba(208, 208, 208, 0.2) 0 1px 2px, rgba(208, 208, 208, 0.2) 0 2px 4px,
    rgba(208, 208, 208, 0.2) 0 4px 8px, rgba(208, 208, 208, 0.2) 0 8px 16px,
    rgba(208, 208, 208, 0.2) 0 16px 32px;
}

.load-button:hover {
  box-shadow: rgba(208, 208, 208, 0.35) 0 -25px 18px -14px inset,
    rgba(208, 208, 208, 0.25) 0 1px 2px, rgba(208, 208, 208, 0.25) 0 2px 4px,
    rgba(208, 208, 208, 0.25) 0 4px 8px, rgba(208, 208, 208, 0.25) 0 8px 16px,
    rgba(208, 208, 208, 0.25) 0 16px 32px; /* Hover box-shadow adjusted to #D0D0D0 */
}
