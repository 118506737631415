@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "GillSans"; /* Should match the font name defined in Tailwind config */
  src: url("./assets/fonts/Gill-Sans.otf"),
    url("./assets/fonts/Gill-Sans-Medium.otf"),
    url("./assets/fonts/Gill-Sans-Bold.otf"),
    url("./assets/fonts/Gill-Sans-Italic.otf"),
    url("./assets/fonts/Gill-Sans-Light-Italic.otf"),
    url("./assets/fonts/Gill-Sans-Medium-Italic.otf"),
    url("./assets/fonts/Gill-Sans-Heavy-Italic.otf"),
    url("./assets/fonts/Gill-Sans-Heavy.otf"),
    url("./assets/fonts/Gill-Sans-Bold-Italic.otf"),
    url("./assets/fonts/GillSans-Condensed.otf"),
    url("./assets/fonts/GillSans-Condensed-Bold.otf"),
    url("./assets/fonts/Gill-Sans-Light.otf");
  /* Add more font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

#root {
  font-family: 'GillSans' !important;
  letter-spacing: 1px;
}
/* 
body {
  font-family: "GillSansBold" !important;
} */


