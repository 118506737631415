.language_bg {
  background: linear-gradient(
    180deg,
    rgba(225, 225, 225, 0.5) 0%,
    #ffffff 100%
  );
  box-shadow: 0px 3.23427px 9.70281px rgba(168, 168, 168, 0.33),
    0px 1.07809px 1.07809px rgba(193, 193, 193, 0.19),
    0px 3.23427px 2.15618px rgba(91, 102, 115, 0.19),
    inset 0px 0px 12.9371px rgba(255, 255, 255, 0.21);
  border-radius: 107.809px;
}

.create_account_bg {
  color: #213660;
  text-align: center;
  text-shadow: 0px 3.398px 0px rgba(185, 224, 253, 0.4);
  font-family: "Gill Sans MT";
  font-size: 13.877px;
  font-style: normal;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 107.517px;
  border: 1.075px solid rgba(104, 157, 211, 0.5);
  background: linear-gradient(
    180deg,
    rgba(104, 151, 211, 10) 0%,
    #a0cbdf 100%
  );

  box-shadow: 0px 4.649px 3.099px 0px rgba(14, 97, 192, 0.19),
    0px 1.55px 1.55px 0px rgba(14, 97, 192, 0.19),
    0px 4.649px 13.946px 0px rgba(0, 128, 161, 0.33),
    0px 0px 18.595px 0px rgba(255, 255, 255, 0.21) inset;
}

.glass_shade_button {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 90%;
  height: 20px !important;
  flex-shrink: 0;
  border-radius: 130.517px;
  margin: auto;
}

.logout_button_bg {
  background: linear-gradient(180deg, rgba(156, 150, 150, 0.5) 0%, #fff 100%);
  box-shadow: 0px 4.532px 3.021px 0px rgba(91, 102, 115, 0.19),
  0px 1.511px 1.511px 0px rgba(193, 193, 193, 0.19),
  0px 4.532px 13.595px 0px rgba(168, 168, 168, 0.33),
  0px 0px 18.127px 0px rgba(255, 255, 255, 0.21) inset;
}

.logout_button_bg:hover {
  background: linear-gradient(
    180deg,
    rgba(180, 167, 167, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.logout_glass_shade {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 97%;
  height: 27.892px;
  flex-shrink: 0;
  border-radius: 154.958px;
  margin: auto;
}