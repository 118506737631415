.sign_in_button {
  background: linear-gradient(
    180deg,
    rgba(104, 151, 211, 0.8) 0%,
    #bae3f6 100%
  );
  box-shadow: 0px 4.64874px 13.9462px rgba(0, 128, 161, 0.33),
    0px 1.54958px 1.54958px rgba(14, 97, 192, 0.19),
    0px 4.64874px 3.09916px rgba(14, 97, 192, 0.19),
    inset 0px 0px 18.595px rgba(255, 255, 255, 0.21);
}

.sign_in_button:hover {
  background: linear-gradient(
    180deg,
    rgba(104, 151, 211, 0.5) 0%,
    rgba(186, 227, 246, 1) 100%
  );
}

.learn_more_button {
  border: 1.511px solid rgba(192, 195, 199, 0.5);
  background: linear-gradient(180deg, rgba(156, 150, 150, 0.5) 0%, #fff 100%);
  box-shadow: 0px 4.532px 3.021px 0px rgba(91, 102, 115, 0.19),
    0px 1.511px 1.511px 0px rgba(193, 193, 193, 0.19),
    0px 4.532px 13.595px 0px rgba(168, 168, 168, 0.33),
    0px 0px 18.127px 0px rgba(255, 255, 255, 0.21) inset;
}

.learn_more_button:hover {
  background: linear-gradient(
    180deg,
    rgba(180, 167, 167, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.glass_shade {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 97%;
  height: 27.892px;
  flex-shrink: 0;
  border-radius: 154.958px;
  margin: auto;
}
