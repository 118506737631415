@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollBar_style {
  height: calc(57px * 4);
}
.scrollBar_style::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scrollBar_style::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
.scrollBar_style::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
.scrollBar_style::-webkit-scrollbar-thumb:hover {
  background: #4a90e2;
}

.border_bottom {
  border-bottom: solid 1px #d1d5db;
}
.border_bottom:last-child {
  border: none;
}

.continue_bg {
  font-family: "Gill Sans MT";
  border-radius: 154.958px;

  background: linear-gradient(
    180deg,
    rgba(104, 151, 211, 0.5) 0%,
    #bae3f6 100%
  );
  box-shadow: 0px 4.649px 3.099px 0px rgba(14, 97, 192, 0.19),
    0px 1.55px 1.55px 0px rgba(14, 97, 192, 0.19),
    0px 4.649px 13.946px 0px rgba(0, 128, 161, 0.33),
    0px 0px 18.595px 0px rgba(255, 255, 255, 0.21) inset;
  border-radius: 154.958px;
  color: #213660;
  text-align: center;
  text-shadow: 0px 3.398px 0px rgba(185, 224, 253, 0.4);
  font-family: "Gill Sans MT";
  font-size: 13.877px;
  font-style: normal;
  line-height: normal;
}

.cance_button_bg {
  text-align: center;
  text-shadow: 0px 3.398px 0px rgba(185, 224, 253, 0.4);
  font-family: "Gill Sans MT";
  font-size: 13.877px;
  font-style: normal;
  line-height: normal;
  background: linear-gradient(180deg, rgba(156, 150, 150, 0.5) 0%, #fff 100%);
  box-shadow: 0px 4.532px 3.021px 0px rgba(91, 102, 115, 0.19),
    0px 1.511px 1.511px 0px rgba(193, 193, 193, 0.19),
    0px 4.532px 13.595px 0px rgba(168, 168, 168, 0.33),
    0px 0px 18.127px 0px rgba(255, 255, 255, 0.21) inset;
}

.cance_button_bg:hover {
  background: linear-gradient(
    180deg,
    rgba(180, 167, 167, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.continue_glass_shade {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 27.892px;
  flex-shrink: 0;
  border-radius: 154.958px;
  margin: auto;
}

.cancel_glass_shade {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 98%;
  height: 27.892px;
  flex-shrink: 0;
  border-radius: 154.958px;
  margin-left: 1.5px;
}
